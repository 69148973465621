import { type MetaField } from '$lib/utils';
import type { CompleteVariant, Money, RequiredProduct, SDSVariant } from '$lib/types';
import { page } from '$app/stores';
import { get } from 'svelte/store';
import slug from 'slug';
import type { ExhaustiveProduct } from './search';
import type { Branch, RootCollection } from '$lib/types/Navigation/branch';
import { type CollectionTree, findInTreeByName } from '$lib/utils/collectionTree';

type NodeInput<E> =
	| {
			edges?: {
				node?: E;
			}[];
	  }
	| undefined
	| null;

export const mapToNode: <E>(input: NodeInput<E>) => E[] = <E>(
	input: NodeInput<E> | undefined | null
) => {
	return (input?.edges?.map(({ node }) => node).filter((s) => !!s) as E[]) ?? [];
};

type Archivable<E> = E & { isArchived: MetaField };

export const removeArchived = <E>(items: Archivable<E>[]): Archivable<E>[] => {
	return items.filter((s) => s.isArchived?.value !== 'true');
};

export const getStructuredData = (
	product: RequiredProduct,
	variant: CompleteVariant,
	sdsVariant: SDSVariant | undefined | null,
	productImages: Array<string>,
	locale: string,
	country: string,
	branch: string
) => {
	const price = variant?.price ?? product.variants.edges[0].node.price;
	const href = variant?.sku?.value
		? `https://www.sprenger.de/${locale}-${country}/${branch}/${slug(product.title)}/${product.handle}?variantSKU=${variant.sku.value}`
		: `https://www.sprenger.de/${locale}-${country}/${branch}/${slug(product.title)}/${product.handle}`;

	return `<script id="${product.id}" type="application/ld+json">{
    "@context": "https://schema.org/",
    "@type": "Product",
    "sku": "${variant?.sku?.value ?? product.sku?.value}",
    "image": [${productImages ? productImages?.map((url) => `"${url}"`).join(', ') : ''}],
    "name": "${`${product.title} ${variant?.title ?? ''}`
			.trim()
			.replace(/\n/g, ' ')
			.replace(/"/g, "'")}",
    "description": "${product?.description?.replace(/\n/g, ' ').replace(/"/g, "'") ?? ''}",
    "brand": {
      "@type": "Brand",
      "name": "Sprenger"
    },
    "offers": {
      "@type": "Offer",
      "url": "${href}",
      "itemCondition": "https://schema.org/NewCondition",
      "availability": "${Math.max(variant.quantityAvailable || 0, sdsVariant?.quantityAvailable || 0) > 0 ? 'https://schema.org/InStock' : 'https://schema.org/OutOfStock'}",
      "price": "${price.amount}",
      "priceCurrency": "${price.currencyCode}",
      "priceValidUntil": "${new Date(
				new Date().valueOf() + 30 * 24 * 60 * 60 * 1000
			).toDateString()}",
      "shippingDetails": {
        "@type": "OfferShippingDetails",
        "shippingRate": {
          "@type": "MonetaryAmount",
          "value": "0",
          "currency": "${price.currencyCode}"
        },
        "shippingDestination": {
          "@type": "DefinedRegion",
          "addressCountry": "${country}"
        },
        "deliveryTime": {
          "@type": "ShippingDeliveryTime",
          "handlingTime": {
            "@type": "QuantitativeValue",
            "minValue": "0",
            "maxValue": "1",
            "unitCode": "d"
          },
          "transitTime": {
            "@type": "QuantitativeValue",
            "minValue": "1",
            "maxValue": "3",
            "unitCode": "d"
          }
        }
      }
    }
  }</script>`;
};

export const parseHighlight = (highlights: MetaField | undefined | null) => {
	try {
		return JSON.parse(highlights?.value ?? '[]')
			.filter((e: string) => !e.includes('§'))
			.join(', ');
	} catch {
		return '';
	}
};

export const formatPrice = (price?: Money | null): string | null => {
	if (!price) {
		return null;
	}
	return new Intl.NumberFormat(get(page).params.locale, {
		style: 'currency',
		currency: price.currencyCode
	}).format(price.amount);
};

export const transformPriceData = (
	data: ExhaustiveProduct['priceRange']
): RequiredProduct['priceRange'] => {
	return {
		minVariantPrice: {
			amount: data.minVariantPrice / 100,
			currencyCode: 'EUR'
		},
		maxVariantPrice: {
			amount: data.maxVariantPrice / 100,
			currencyCode: 'EUR'
		}
	};
};

export const COLLECTION_TO_LANDING_PAGE_LOOKUP: Record<RootCollection, string> = {
	'pferdesport-root': '/[locale]-[country]/l/reitsport',
	'hundesport-root': '/[locale]-[country]/l/hundesport',
	'bootsport-root': '/[locale]-[country]/l/bootsport-vc-17m-verbot',
	'sprenger-me-root': '/[locale]-[country]/sprenger-me-root',
	'300d1734': '/[locale]-[country]/l/reitsport',
	'825406ab': '/[locale]-[country]/l/hundesport',
	'1bec92b0': '/[locale]-[country]/l/bootsport-vc-17m-verbot',
	'2035d535': '/[locale]-[country]/sprenger-me-root'
};

export const COLLECTION_BRANCH_LOOKUP: Record<RootCollection, Branch> = {
	'hundesport-root': 'dog-sport',
	'bootsport-root': 'boat-sport',
	'pferdesport-root': 'equine-sport',
	'sprenger-me-root': 'sprenger-and-me'
};

export const COLLECTION_TO_SELECTION_LOOKUP: Record<RootCollection | 'faq', string> = {
	'hundesport-root': 'hundesport-selection',
	'bootsport-root': 'bootsport-selection',
	'pferdesport-root': 'pferdesport-selection',
	'sprenger-me-root': 'sprenger-me-root',
	faq: 'neuheiten-root'
};

export const BRANCH_COLLECTION_LOOKUP: Record<Branch, RootCollection> = Object.fromEntries(
	Object.entries(COLLECTION_BRANCH_LOOKUP).map(([key, value]) => [value, key])
);

export const percentOf = (current?: Money | null, compareAt?: Money | null) => {
	const compareAtAmount = compareAt?.amount ?? 0;
	const currentAtAmount = current?.amount ?? 0;
	if (compareAtAmount === 0) {
		return 0;
	}
	const diff = currentAtAmount / compareAtAmount;
	return Math.round((1 - diff) * 100);
};

const roundPriceToShopify = (input: number) => Math.ceil(input * 100) / 100;

/**
 * Calculates the new price after applying the discount
 *
 * This function rounds the last digit up like shopify eg.
 * 15.121 -> 15.13
 * @param price the original price
 * @param percentageOff the discount amount in decimal
 */
export const getPriceAfterDiscount = (price: Money | undefined | null, percentageOff: number) => {
	if (!price) {
		return null;
	}

	const calculatedPrice = price.amount * (1 - percentageOff);
	// Shopify Rounds the cent price up, so we do it too :)
	return {
		...price,
		amount: roundPriceToShopify(calculatedPrice)
	};
};

export const transformProductCollectionsToBranch = (
	data: NodeInput<{ path: { value: string } }>
): string | undefined => {
	return JSON.parse(data?.edges?.[0]?.node?.path.value || '[{"url_key":"Hundesport"}]')[0].url_key;
};

export const transformSearchCollectionToBranch = (
	data: ExhaustiveProduct['collections']
): 'dog-sport' | 'boat-sport' | 'equine-sport' | string | undefined => {
	const collectionTree: CollectionTree = get(page).data.collectionTree;
	for (const item of data) {
		const treeElement = findInTreeByName(collectionTree, item.name);
		if (!treeElement) {
			continue;
		}
		const branch = COLLECTION_BRANCH_LOOKUP[treeElement.link];
		if (branch) {
			return branch;
		}
	}
};

export const formatProductTitle = (productTitle: string, variantTitle?: string) =>
	variantTitle && variantTitle !== '1' ? `${productTitle} (${variantTitle})` : productTitle;
